import React from 'react';
import { MessageDiv, StyledArticle } from '../../styles';

import { Layout } from '../../components/Layout';
import { StyledH2 } from '../../styles/common';
import { StyledTable } from '../../styles/diagnostyka';
import { SEO } from '../../components/SEO';

export function Head() {
	return (
		<SEO
			title="Reklama"
			description="Cennik i zasady najmu powierzchni reklamowej."
		/>
	);
}

function ReklamaPage() {
	return (
		<Layout>
			<StyledArticle>
				<StyledH2 as="h1" color="info">
					Reklama
				</StyledH2>
				<MessageDiv>
					<h2>
						Cennik najmu powierzchni reklamowej (cena netto w zł za 1 miesiąc)
					</h2>
					<StyledTable style={{ width: '100%' }}>
						<tbody>
							<tr>
								<td colSpan="2">AUTOBUSY</td>
							</tr>
							<tr>
								<td>
									FULLBACK ( tył autobusu : szyba i powierzchnia lakierowana)
								</td>
								<td style={{ width: '80px', textAlign: 'right' }}>1000,00</td>
							</tr>
							<tr>
								<td>
									JEDNA BURTA AUTOBUSU KRÓTKIEGO (powierzchnia lakierowana)
								</td>
								<td style={{ width: '80px', textAlign: 'right' }}>700,00</td>
							</tr>
							<tr>
								<td>
									JEDNA BURTA AUTOBUSU DŁUGIEGO (powierzchnia lakierowana)
								</td>
								<td style={{ width: '80px', textAlign: 'right' }}>1000,00</td>
							</tr>
						</tbody>
					</StyledTable>
					<br />
					<StyledTable style={{ width: '100%' }}>
						<tbody>
							<tr>
								<td colSpan="2">TRAMWAJ 805 Na – 1 WAGON</td>
							</tr>
							<tr>
								<td>JEDNA BURTA (powierzchnia lakierowana)</td>
								<td style={{ width: '80px', textAlign: 'right' }}>800,00</td>
							</tr>
						</tbody>
					</StyledTable>
					<br />
					<StyledTable style={{ width: '100%' }}>
						<tbody>
							<tr>
								<td colSpan="2">POWIERZCHNIA NA OBIEKTACH</td>
							</tr>
							<tr>
								<td>
									1M<sup>2</sup>
								</td>
								<td style={{ width: '80px', textAlign: 'right' }}>70,00</td>
							</tr>
						</tbody>
					</StyledTable>
					<ul style={{ listStyle: 'decimal', paddingLeft: '16px' }}>
						<li>
							Umowy najmu powierzchni reklamowej, zawierane są na okresy
							stanowiące krotność jednego miesiąca.
						</li>
						<li>Płatności wnoszone są jednorazowo, w pełnej wysokości.</li>
						<li>
							W przypadku zawarcia umowy na czas dłuższy niż 5 miesięcy, cena za
							cały okres najmu zostaje obniżona o 10 %.
						</li>
						<li>
							W przypadku zawarcia umowy na najem powierzchni na wspólny okres,
							z wykorzystaniem powyżej 10 produktów (dopuszcza się łączenie
							różnych form powierzchni na jednym pojeździe), cena najmu w ramach
							tej umowy zostaje obniżona o 10%.
						</li>
						<li>
							Maksymalny rabat naliczany w ramach jednej umowy wynosi 15%.
						</li>
						<li>
							Umowy zawierane są oddzielnie na najem powierzchni na autobusach,
							tramwajach i obiektach.
						</li>
					</ul>
					<p>
						Do cen netto dolicza się kwotę obowiązującego podatku od towarów i
						usług (VAT).
					</p>

					<h2>Zasady realizacji usług reklamowych.</h2>
					<div>
						<p>
							<b>Zakres oferty</b>
						</p>
						MZK Sp. z o.o. z siedzibą w Bydgoszczy, zwana dalej MZK, świadczy
						usługi najmu powierzchni pod reklamę umożliwiając następujące formy
						i zasady ekspozycji reklamy na niżej wymienionych nośnikach:
						<h4 className="text-primary">
							1. Zewnętrzne powierzchnie taboru liniowego.
						</h4>
						<ul style={{ listStyle: 'none' }}>
							<li>
								1) Tabor autobusowy:
								<ul style={{ listStyle: 'lower-alpha' }}>
									<li>
										reklama na taborze autobusowym może być umieszczona po
										pięcioletnim okresie eksploatacji licząc od daty zakupu
										fabrycznie nowego taboru.
									</li>
									<li>
										reklama na autobusach może być umieszczona na bocznych
										powierzchniach lakierowanych oraz w formie fullback tj. na
										tylnej powierzchni lakierowanej oraz szybie.
									</li>
									<li>
										reklama nie może być zamieszczona na:
										<ul style={{ listStyle: 'square' }}>
											<li>
												powierzchni ściany przedniej kończącej się z prawej
												strony na początku przednich drzwi i z lewej strony na
												tej samej wysokości,
											</li>
											<li>powierzchni drzwi,</li>
											<li>przednich i bocznych szybach pojazdu.</li>
										</ul>
									</li>
									<li>
										reklama nie może zakrywać ekranów z nr linii i kierunkowych,
										tablicy rejestracyjnej, elementów wyposażenia pojazdu np.
										lamp zespolonych, obrysowych, przycisków otwierania drzwi
										oraz znaków informacyjnych w formie piktogramów
										zamieszczonych na poszyciu pojazdów. Na elementach ruchomych
										(pokrywy, klapy) folia musi być rozcięta.
									</li>
								</ul>
							</li>
							<li>
								2) Tabor tramwajowy:
								<ul style={{ listStyle: 'lower-alpha' }}>
									<li>
										reklama na taborze tramwajowym może być umieszczona po
										pięcioletnim okresie eksploatacji licząc od daty zakupu
										fabrycznie nowego taboru lub wykonania remontu kapitalnego
										wagonu.
									</li>
									<li>
										reklama całopojazdowa może być umieszczona na powierzchniach
										bocznych (burty).{' '}
									</li>
									<li>
										reklama nie może zakrywać znaków informacyjnych w formie
										piktogramów zamieszczonych na poszyciu pojazdów oraz
										przycisków otwierania drzwi.
									</li>
								</ul>
							</li>
						</ul>
						<p>
							Kontakt w sprawie zewnętrznych powierzchni taboru liniowego:
							<br />
							<br />
							<b>Sekcja Administracyjno-Kancelaryjna</b>
							<br />
							tel.:&nbsp;
							<a href="tel:523249424">(52)&nbsp;324&nbsp;94&nbsp;24</a>{' '}
							email:&nbsp;
							<a href="mailto:reklama@mzk.bydgoszcz.pl">
								reklama@mzk.bydgoszcz.pl
							</a>
						</p>
						<h4 className="text-primary pt25">
							2. Wewnętrzne powierzchnie taboru liniowego.
						</h4>
						Reklamy mogą być zamieszczane wewnątrz pojazdów w formie plakatów,
						uchwytów na ulotki reklamowe.
						<br />
						<br />
						W sprawie ekspozycji plakatów prosimy o kontakt:
						<br />
						<b>AUTOBOX ADVERTISING Sp. z o.o.</b>
						<h4 className="text-primary">
							3. Reklamy na nieruchomościach MZK.
						</h4>
						<div>
							<p>
								Przy realizacji reklamy na nieruchomościach MZK należy wymagać
								od najemcy spełnienia wymogów ustawy Prawo budowlane. Reklamy
								mogą być umieszczane na budynkach i ogrodzeniach.
							</p>
							<p>
								<b>Warunki wstępne umowy</b>
							</p>
							<ul style={{ listStyle: 'decimal' }}>
								<li>
									Zawarcie umowy o świadczenie usług reklamowych musi być
									poprzedzone przedłożeniem aktualnego odpisu z krajowego
									rejestru sądowego lub wydruku z centralnej ewidencji
									informacji o działalności gospodarczej.
								</li>
								<li>
									MZK wskazuje Najemcy do wyboru typy tramwaju lub autobusu pod
									ekspozycję reklamy, jednak bez możliwości wyboru konkretnej
									linii komunikacyjnej, na której pojazd będzie eksploatowany.
								</li>
								<li>
									Wszelkie koszty związane z przygotowaniem projektu, wykonaniem
									reklamy i montażem ponosi Najemca.
								</li>
								<li>
									Najemca zobowiązany jest przy wykonaniu reklamy zastosować
									materiały dobrej jakości niepowodujące uszkodzeń powłok
									lakierniczych.
								</li>
							</ul>
						</div>
						<div>
							<p>
								<b>Treść i zmiany treści reklamy</b>
							</p>
							<ul style={{ listStyle: 'decimal' }}>
								<li>
									Najemca ponosi odpowiedzialność wobec osób trzecich za
									ewentualne wyrządzone im szkody związane z faktem umieszczenia
									reklamy, w szczególności wynikające z ich treści lub stanu
									technicznego.
								</li>
								<li>
									Reklama nie może naruszać obowiązujących przepisów w RP, a w
									szczególności stanowić naruszenia dóbr osobistych, czynu
									nieuczciwej konkurencji, a także ze względu na treść lub formę
									godzić w dobre obyczaje.
								</li>
								<li>
									MZK zastrzega sobie prawo do usunięcia reklamy w przypadkach
									określonych w ust 2, bez prawa Najemcy do jakichkolwiek
									roszczeń z tego tytułu.
								</li>
								<li>
									Przed podpisaniem umowy projekt reklamy zatwierdza Sekcja
									Administracyjno-Kancelaryjna MZK, a w przypadku umów
									zawieranych na czas dłuższy niż jeden miesiąc projekt reklamy
									musi być zatwierdzony przez Plastyka Miejskiego. Z wnioskiem o
									zatwierdzenie projektu reklamy do Plastyka Miejskiego
									występuje Najemca.
									<br />
									<div>
										<br />
										<b>Plastyk Miejski</b>
										<br />
										tel.{' '}
										<a href="tel:525858177">(52)&nbsp;58&nbsp;58&nbsp;177</a>,
										e-mail:{' '}
										<a href="mailto:m.iwinski@um.bydgoszcz.pl">
											m.iwinski@um.bydgoszcz.pl
										</a>
										<br />
										<br />
									</div>
								</li>

								<li>
									W trakcie trwania umowy, Najemca może za zgodą MZK dokonać
									zmiany treści reklamy.
								</li>
								<li>
									Zmiany treści reklamy dokonuje się po zatwierdzeniu przez
									Sekcję Administracyjno-Kancelaryjną MZK oraz Plastyka
									Miejskiego nowego projektu.
								</li>
								<li>
									Zmiana treści reklamy oznacza jej ponowne wykonanie zgodnie z
									warunkami umowy.
								</li>
							</ul>
						</div>
					</div>
				</MessageDiv>
			</StyledArticle>
		</Layout>
	);
}

export default ReklamaPage;
